/**
 * Created by TapFun on 2019/3/1.
 */
import axios from 'axios';
import qs from 'qs';
import {SERVER_HOST} from './../common/portConfig'

var instance = axios.create({
  headers: {
    'Authorization': ''
  }
});

instance.interceptors.request.use(
  config => {
    config.headers.Authorization = sessionStorage.getItem('Authorization');
    return config
  }, err => {
    return Promise.reject(err)
  });



// 系统登录
export const requestSystemLogin = params => {return instance.post(`${SERVER_HOST}/systemLogin`, qs.stringify(params)).then(res => res.data)};

//下载excel
export const downExcel = `${SERVER_HOST}/file/downExcel/`;

export const reqGetUserListPage = params => { return instance.get(`${SERVER_HOST}/adminUser/pageQuery`, { params: params }) };
export const reqAddUser = params => { return instance.post(`${SERVER_HOST}/adminUser/add`, qs.stringify(params) ) };
export const reqEditUser = params => { return instance.post(`${SERVER_HOST}/adminUser/edit`, qs.stringify(params) ) };
export const reqEditUserPWD = params => { return instance.post(`${SERVER_HOST}/admin/update/pwd`, qs.stringify(params) ) };
export const reqDeleteUser = params => { return instance.get(`${SERVER_HOST}/adminUser/del`, { params: params } ) };
export const reqAdminUserBatchDelete = params => { return instance.post(`${SERVER_HOST}/adminUser/adminUserBatchDelete`, params)};
export const reqSaveUserProfile = params => { return instance.post(`${SERVER_HOST}/user/profile`, params).then(res => res.data) };

export const reqGetRoleListPage = params => { return instance.get(`${SERVER_HOST}/adminRole/pageQuery`, { params: params }) };
export const reqAddRole = params => { return instance.post(`${SERVER_HOST}/adminRole/add`, qs.stringify(params))};
export const reqEditRole = params => { return instance.post(`${SERVER_HOST}/adminRole/edit`, qs.stringify(params)) };
export const reqDeleteRole = params => { return instance.get(`${SERVER_HOST}/adminRole/del`, { params: params } ) };
export const reqGetRoleListAll = params => { return instance.get(`${SERVER_HOST}/adminRole/queryAll`, { params: params }) };

export const reqGetLeftMenus = params => { return instance.get(`${SERVER_HOST}/adminPermission/leftMenu`, { params: params }) };
export const queryAdminPermissions = params => { return instance.get(`${SERVER_HOST}/adminPermission/queryAdminPermissions`, { params: params }) };
export const reqGetMenuListPage = params => { return instance.get(`${SERVER_HOST}/adminPermission/pageQuery`, { params: params }) };
export const reqGetParentMenuList = params => { return instance.get(`${SERVER_HOST}/adminPermission/query/parentPermission`, { params: params }) };
export const getAllParentPermission = params => { return instance.get(`${SERVER_HOST}/adminPermission/queryAllParentPermission`, { params: params }) };
export const reqGetPermissionButton = params => { return instance.get(`${SERVER_HOST}/adminPermission/queryPermissionButton`, { params: params }) };
export const reqParentAndChildPermission = params => { return instance.get(`${SERVER_HOST}/adminPermission/query/queryParentAndChildPermission`, { params: params }) };
export const reqAddMenu = params => { return instance.post(`${SERVER_HOST}/adminPermission/add`, qs.stringify(params)) };
export const reqEditMenu = params => { return instance.post(`${SERVER_HOST}/adminPermission/edit`, qs.stringify(params)) };
export const reqDeleteMenu = params => { return instance.get(`${SERVER_HOST}/adminPermission/del`, { params: params }) };
export const isExistChilds = params => { return instance.get(`${SERVER_HOST}/adminPermission/isExistChilds`, { params: params }) };
export const reqGetAllMenuList = params => { return instance.get(`${SERVER_HOST}/adminPermission/queryAdminPermission`, { params: params }) };
export const reqGetPermissionByRoleId = params => { return instance.get(`${SERVER_HOST}/adminPermission/queryPermissionByRoleId`, { params: params }) };

export const reqGetDictionaryList = params => { return instance.get(`${SERVER_HOST}/dictionary/list`, { params: params }) };
export const reqGetDictionaryListByCode = params => { return instance.get(`${SERVER_HOST}/dictionary/getListByCode`, { params: params } ) };
export const reqGetDictionaryParentList = params => { return instance.get(`${SERVER_HOST}/dictionary/getParentList`, { params: params } ) };
export const reqGetDictionaryByIdList = params => { return instance.get(`${SERVER_HOST}/dictionary/getByIdList`, { params: params } ) };
export const reqAddDictionary = params => { return instance.post(`${SERVER_HOST}/dictionary/add`, params) };
export const reqEditDictionary = params => { return instance.post(`${SERVER_HOST}/dictionary/edit`, params) };
export const reqDeleteDictionary = params => { return instance.delete(`${SERVER_HOST}/dictionary/delete/`+params.id, params ) };
export const reqBatchDeleteDictionary = params => { return instance.post(`${SERVER_HOST}/dictionary/batchdelete`, params) };
export const reqGetDictionaryOne = params => { return instance.get(`${SERVER_HOST}/dictionary/one`, { params: params } ) };

export const reqGetOperationlogList = params => { return instance.get(`${SERVER_HOST}/operationlog/list`, { params: params }) };
export const reqDeleteOperationlog = params => { return instance.delete(`${SERVER_HOST}/operationlog/delete/`+params.id, params ) };
export const reqBatchDeleteOperationlog = params => { return instance.post(`${SERVER_HOST}/operationlog/batchdelete`, params) };

//首页
export const reqAdminStatistic = params => { return instance.get(`${SERVER_HOST}/systemDashboard/adminStatistic`, { params: params } ) };

export const reqAdminGraphicStatistics = params => { return instance.get(`${SERVER_HOST}/systemDashboard/adminGraphicStatistics`, { params: params } ) };

export const uploadFileURL = `${SERVER_HOST}/file/uploadFile`;
export const downloadFileURL = `${SERVER_HOST}/file/downloadFile/`;
//上传音频
export const uploadAudioURL = `${SERVER_HOST}/file/uploadAudio`;
export const downloadAudioURL = `${SERVER_HOST}/file/downloadFile/`;
// wangEditor上传图片和上传视频
export const editorUploadFileURL = `${SERVER_HOST}/editorFile/uploadFile/`;
export const editorUploadVideoFileURL = `${SERVER_HOST}/editorFile/uploadVideoFile/`;

export const reqUpdateAdminUserPwd = params => { return instance.post(`${SERVER_HOST}/adminUser/updateAdminUserPwd`, params ) };
export const reqUserNameByToken = params => { return instance.post(`${SERVER_HOST}/adminUser/userNameByToken`, params) };
export const reqBacklogCount = params => { return instance.get(`${SERVER_HOST}/backlog/count`, { params: params }) };

// 获取图片验证码：
export const reqCodeImg = params => { return instance.get(`${SERVER_HOST}/getCodeImg`, {params: params}) };
